@tailwind base;
@tailwind components;
@tailwind utilities;

@layer invisible-scrollbar {
    .invisible-scrollbar::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        
        /* scrollbar-width: none; */
        /* position: relative; */
        /* overflow-y: auto; */
    }
}

@layer hide-input-number-spin {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
}

/* input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
} */

/* @layer hide-input-number-arrow {
    .hide-input-number-arrow
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  } */