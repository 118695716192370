html,
body,
#root,
#wrapper {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "OnestBlack";
  src: local("OnesBlack"), url("../src/styles/fonts/OnestBlack1602-hint.ttf");
}

;

@font-face {
  font-family: "OnestBold";
  src: local("OnestBold"), url("../src//styles/fonts/OnestBold1602-hint.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "OnestExtraBold";
  src: local("OnestExtraBold"), url("../src/styles/fonts/OnestExtraBold1602-hint.ttf");
  font-weight: bolder;
}

@font-face {
  font-family: "OnestLight";
  src: local("OnestLight") url("../src/styles/fonts/OnestLight1602-hint.ttf");
  font-weight: lighter;
}

@font-face {
  font-family: "OnestMedium";
  src: local("OnestMedium"), url("../src/styles/fonts/OnestMedium1602-hint.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "OnestRegular";
  src: local("OnestRegular") url("../src/styles/fonts/OnestRegular1602-hint.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "OnestThin";
  src: local("OnestThin") url("../src/styles/fonts/OnestThin1602-hint.ttf");
  font-weight: 300;
}